@font-face {
  font-family: 'Helvetica Neue Thin';
  src: url('../assets/fonts/HelveticaNeueCyr-Thin.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('../assets/fonts/HelveticaNeueCyr-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Roman';
  src: url('../assets/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Ultra Light';
  src: url('../assets/fonts/HelveticaNeueCyr-UltraLight.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
