@font-face {
  font-family: 'ClashDisplay';
  src: url('../assets/fonts/ClashDisplay/ClashDisplay-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../assets/fonts/ClashDisplay/ClashDisplay-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../assets/fonts/ClashDisplay/ClashDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay';
  src: url('../assets/fonts/ClashDisplay/ClashDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
