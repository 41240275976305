// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
.ion-color-new {
  --ion-color-base: var(--ion-color-new, #6fef90) !important;
  --ion-color-base-rgb: var(--ion-color-new-rgb, 111, 239, 144) !important;
  --ion-color-contrast: var(--ion-color-new-contrast, #000) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-new-contrast-rgb,
    0,
    0,
    0
  ) !important;
  --ion-color-shade: var(--ion-color-new-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-new-tint, #ffca22) !important;
}

.ion-color-available {
  --ion-color-base: var(--ion-color-available, #6fef90) !important;
  --ion-color-base-rgb: var(
    --ion-color-available-rgb,
    111,
    239,
    144
  ) !important;
  --ion-color-contrast: var(--ion-color-available-contrast, #000) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-available-contrast-rgb,
    0,
    0,
    0
  ) !important;
  --ion-color-shade: var(--ion-color-available-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-available-tint, #ffca22) !important;
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google, #6fef90) !important;
  --ion-color-base-rgb: var(--ion-color-google-rgb, 111, 239, 144) !important;
  --ion-color-contrast: var(--ion-color-google-contrast, #000) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-google-contrast-rgb,
    0,
    0,
    0
  ) !important;
  --ion-color-shade: var(--ion-color-google-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-google-tint, #ffca22) !important;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook, #6fef90) !important;
  --ion-color-base-rgb: var(--ion-color-facebook-rgb, 111, 239, 144) !important;
  --ion-color-contrast: var(--ion-color-facebook-contrast, #000) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-facebook-contrast-rgb,
    0,
    0,
    0
  ) !important;
  --ion-color-shade: var(--ion-color-facebook-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-facebook-tint, #ffca22) !important;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white, #6fef90) !important;
  --ion-color-base-rgb: var(--ion-color-white-rgb, 111, 239, 144) !important;
  --ion-color-contrast: var(--ion-color-white-contrast, #000) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-white-contrast-rgb,
    0,
    0,
    0
  ) !important;
  --ion-color-shade: var(--ion-color-white-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-white-tint, #ffca22) !important;
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey, #6fef90);
  --ion-color-base-rgb: var(--ion-color-grey-rgb, 111, 239, 144);
  --ion-color-contrast: var(--ion-color-grey-contrast, #000);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb, 0, 0, 0);
  --ion-color-shade: var(--ion-color-grey-shade, #e0ac08);
  --ion-color-tint: var(--ion-color-grey-tint, #ffca22);
}

.ion-color-mediumgrey {
  --ion-color-base: var(--ion-color-mediumgrey);
  --ion-color-base-rgb: var(--ion-color-mediumgrey-rgb);
  --ion-color-contrast: var(--ion-color-mediumgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mediumgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-mediumgrey-shade);
  --ion-color-tint: var(--ion-color-mediumgrey-tint);
}

.ion-color-teal {
  --ion-color-base: var(--ion-color-teal);
  --ion-color-base-rgb: var(--ion-color-teal-rgb);
  --ion-color-contrast: var(--ion-color-teal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal-shade);
  --ion-color-tint: var(--ion-color-teal-tint);
}
.ion-color-darkteal {
  --ion-color-base: var(--ion-color-darkteal);
  --ion-color-base-rgb: var(--ion-color-darkteal-rgb);
  --ion-color-contrast: var(--ion-color-darkteal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkteal-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkteal-shade);
  --ion-color-tint: var(--ion-color-darkteal-tint);
}

html.ios {
  --ion-native-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    "Roboto", sans-serif;
}
html.md {
  --ion-native-font-family: "Roboto", "Helvetica Neue", sans-serif;
}

/** Ionic CSS Variables **/
:root {
  --ion-background-color: var(--white);

  --ion-font-family: "ClashDisplay", serif;

  --ion-color-img-border: #f3f3f3;

  --ion-app-tabs-height: 48px;

  --ion-color-original-primary: #3880ff;
  --ion-color-original-primary-rgb: 56, 128, 255;
  --ion-color-original-primary-contrast: #ffffff;
  --ion-color-original-primary-contrast-rgb: 255, 255, 255;
  --ion-color-original-primary-shade: #3171e0;
  --ion-color-original-primary-tint: #4c8dff;

  --ion-color-primary: rgba(0, 204, 204, 1);
  --ion-color-primary-rgb: 133, 199, 45;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a6a6;
  --ion-color-primary-tint: #009b9b;

  --ion-color-secondary: #0086d7;
  --ion-color-secondary-rgb: 108, 196, 173;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0076bd;
  --ion-color-secondary-tint: #1a92db;

  --ion-color-tertiary: #ffc727;
  --ion-color-tertiary-rgb: 255, 199, 39;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #e0af22;
  --ion-color-tertiary-tint: #ffcd3d;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #13110c;
  --ion-color-dark-rgb: 51, 51, 51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #666666;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #13110c;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #666666;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** new **/
  --ion-color-mediumgrey: #c0c0c0;
  --ion-color-mediumgrey-rgb: 102, 102, 102;
  --ion-color-mediumgrey-contrast: #000000;
  --ion-color-mediumgrey-contrast-rgb: 0, 0, 0;
  --ion-color-mediumgrey-shade: #a9a9a9;
  --ion-color-mediumgrey-tint: #c6c6c6;

  --ion-color-teal: #19d1d1;
  --ion-color-teal-rgb: 25, 209, 209;
  --ion-color-teal-contrast: #ffffff;
  --ion-color-teal-contrast-rgb: 0, 0, 0;
  --ion-color-teal-shade: #00625c;
  --ion-color-teal-tint: #1a7d78;

  --ion-color-darkteal: #297b7b;
  --ion-color-darkteal-rgb: 41, 123, 123;
  --ion-color-darkteal-contrast: #ffffff;
  --ion-color-darkteal-contrast-rgb: 0, 0, 0;
  --ion-color-darkteal-shade: #00625c;
  --ion-color-darkteal-tint: #1a7d78;

  --ion-color-new: #4bd48f;
  --ion-color-new-rgb: 75, 212, 143;
  --ion-color-new-contrast: #000000;
  --ion-color-new-contrast-rgb: 0, 0, 0;
  --ion-color-new-shade: #42bb7e;
  --ion-color-new-tint: #5dd89a;

  --ion-color-available: #6fef90;
  --ion-color-available-rgb: 111, 239, 144;
  --ion-color-available-contrast: #000000;
  --ion-color-available-contrast-rgb: 0, 0, 0;
  --ion-color-available-shade: #62d27f;
  --ion-color-available-tint: #7df19b;

  --ion-color-mblack: #000000;
  --ion-color-mblack-rgb: 0, 0, 0;
  --ion-color-mblack-contrast: #ffffff;
  --ion-color-mblack-contrast-rgb: 255, 255, 255;
  --ion-color-mblack-shade: #000000;
  --ion-color-mblack-tint: #1a1a1a;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-grey: #f0f0f0;
  --ion-color-grey-rgb: 240, 240, 240;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #000000;
  --ion-color-grey-tint: #000000;

  --ion-color-google: #ce3232;
  --ion-color-google-rgb: 206, 50, 50;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #b52c2c;
  --ion-color-google-tint: #d34747;

  --ion-color-facebook: #3a5b9a;
  --ion-color-facebook-rgb: 58, 91, 154;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #335088;
  --ion-color-facebook-tint: #4e6ba4;
}

// @media (prefers-color-scheme: dark) {
body.dark {
  --ion-color-medium: #222222;
  --ion-color-medium-tint: #222222;
  --ion-color-grey: #282828;

  --ion-color-primary: rgba(0, 204, 204, 1);
  --ion-color-primary-rgb: 133, 199, 45;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00a6a6;
  --ion-color-primary-tint: #009b9b;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
  * iOS Dark Theme
  * -------------------------------------------
  */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

/*
  * Material Design Dark Theme
  * -------------------------------------------
  */

.md body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}
