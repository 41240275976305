/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/font-aeonik.css";
@import "theme/font-clash-display.css";
@import "theme/font-helvetica-neue.css";

@import "~swiper/swiper-bundle.css";

/*new design joe*/
:root {
  --color-primary-stramapp: rgb(25 209 209);
  --btn-default-app: rgb(2 106 104);
  --btn-defaultactive: var(--white);
  --dark: #272727;
  --dark2: #3c3c3c;
  --dark3: #5f5f5f;
  --white: #ffffff;
  --black: #000000;
  --lightgray: #f2f2f2;
  --lightgray2: #ededed;
  --lightgray3: #f0f0f0;
  --lightgray4: #dedede;
  --lightgray5: #e5e5e5;
  --lightgray6: #f7f7f7;
  --lightgray7: #e3e3e3;
  --lightgray8: #f1f1f1;
  --lightgray9: #e9e9e9;
  --lightgray10: #f2f2f2;
  --lightblack: rgba(0, 0, 0, 0.6);
  --lightblack2: rgba(0, 0, 0, 0.22);
  --default-shadow: 0px 0px 1px rgba(0, 0, 0, 0.9);
}

body.dark {
  // :root {
    --dark: #eeeeee;
    --dark2: #dddddd;
    --dark3: #bbbbbb;
    --white: black;
    --black: #fafafa;
    --background: #111111;
    --color: #fafafa;
    --text-color: #fafafa;
    --lightgray: #222222;
    --lightgray2: #333333;
    --lightgray3: #222222;
    --lightgray4: #444444;
    --lightgray5: #333333;
    --lightgray6: #222222;
    --lightgray7: #333333;
    --lightgray8: #1a1a1a;
    --lightgray9: #222222;
    --lightgray10: #444444;
    --ion-text-color: #fafafa;
    --lightblack: rgba(255, 255, 255, 0.6);
    --lightblack2: rgba(255, 255, 255, 0.22);
    --ion-color-img-border: #111111;
  // }
}



*:focus {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}

ion-button {
  text-transform: none;
  font-size: 16px;
  --box-shadow: none;
  --border-radius: 10px;
  --padding-top: 25px;
  --padding-bottom: 25px;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
  --background-hover-opacity: 0;
}

/*
 * Robert
 * **********************
 */

.general-header-app {
  ion-toolbar {
    display: flex;
    --background: var(--white);
    padding-top: var(--ion-safe-area-top) !important;

    ion-buttons {
      height: 100%;

      ion-button {
        &:not(.fullWidth) {
          width: 44px;
        }

        height: 44px;
        --border-radius: 100px !important;
        --color: var(--black);

        &::part(native) {
          padding: 0;
          color: var(--dark);
        }

        ion-icon {
          width: 24px;
          height: 24px;
          pointer-events: none;
          stroke: var(--black);

          &.share {
            color: transparent !important;
            stroke-width: 4px;
          }
        }

        svg {
          height: 22px;
          width: 22px;
          pointer-events: none;
        }

        &.reduced {
          width: 40px;
          height: 40px;
          fill: var(--black);
        }

        &.dots {
          svg {
            height: 19px;
            width: 19px;
            transform: translateY(-0.5px);
          }
        }

        &.menu {
          ion-icon {
            width: 22px;
            height: 22px;
          }
        }

        &.ghost {
          pointer-events: none !important;
        }
      }
    }

    ion-title {
      padding: 0 10px;
      font-size: 19px;
      font-weight: 500;
      // position: relative;

      &.ios {
        width: calc(100% - 100px);
        left: 50px;

        &.hideRightButtons {
          width: calc(100% - 50px);
        }
      }

      .filter-button {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        ion-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

img,
ion-img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

ion-button {
  min-height: unset;

  &:part(native) {
    min-height: unset;
  }
}

ion-tab-bar,
ion-tab-button {
  background-color: var(--white);
}

ion-toggle {
  height: 25px;
  width: 45px;
  --handle-width: 21px;

  &::part(track) {
    background: rgba(127,127,127,0.3);
  }
  &.toggle-checked::part(track) {
    background: rgb(2,204,204);
  }
}

ion-item {
  --background-focused-opacity: 0;
  --highlight-height: 0px;
}

ion-toast {
  font-family: "Helvetica", serif;

  &.primary-contrast {
    color: var(--black);
  }
}

ion-textarea {
  .textarea-wrapper {
    word-break: break-word !important;
  }
  .textarea-wrapper-inner {
    min-height: fit-content !important;
  }
}

ion-thumbnail {
  user-select: none;

  img {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

ion-img {
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

ion-datetime {
  font-family: var(--ion-native-font-family) !important;

  ion-button {
    font-family: var(--ion-native-font-family) !important;
  }
}

ion-refresher {
  ion-refresher-content {
    ion-spinner,
    ion-icon {
      color: var(--ion-color-primary);
    }
  }
}
ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

ion-modal:not(.no-ion-datetime):has(ion-datetime) {
  &::part(content) {
    border-radius: 20px;
  }
}

ion-alert {
  &.select-alert {
    .alert-wrapper {
      padding-top: 0 !important;

      .alert-message {
        display: none;
      }

      .alert-radio-group {
        padding-top: 20px;
        border-top: 0;
        border-bottom: 1px solid #e6e6e6;
        .alert-radio-label {
          color: var(--dark);
        }
      }

      .alert-button-group {
        margin-top: 10px;
      }
    }
  }
}

ion-input {
  min-height: fit-content !important;
}

ion-select {
  min-height: fit-content;
}

ion-header {
  box-shadow: none !important;
}

.ios-safe-space-top {
  height: env(safe-area-inset-top);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ios-bottom-safe-area {
  width: 100%;
  height: env(safe-area-inset-bottom);
}

.disabler {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.inline-modal {
  --height: fit-content;
  --max-height: 85%;

  &::part(content) {
    max-width: 500px;
  }
}

.page-modal {
  .ion-page {
    display: contents;
  }
}

.fit-modal {
  --width: fit-content;
  --height: fit-content;

  &.floating-modal {
    &::part(content) {
      background-color: transparent;
    }
  }
  &.box-shadow-none {
    &::part(content) {
      box-shadow: none;
    }
  }

  &.profile-avatar {
    --backdrop-opacity: 0.7;
  }

  &.info-modal {
    &::part(content) {
      background-color: transparent;
    }

    .inner {
      max-width: 90vw;
      width: 350px;
      padding: 15px;
      background-color: var(--white);
      border-radius: 20px;
      display: flex;

      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 44px;
        height: 44px;
        border-radius: 100px;
        color: var(--black);
        margin: 0;

        &::part(native) {
          padding: 0;
        }
      }

      .icon {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: #767474;
        font-size: 40px;
      }

      .message {
        display: flex;
        align-items: center;
        width: 100%;
        color: #111;
        padding-left: 10px;
        padding-right: 25px;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
          Roboto, "Helvetica Neue", Arial, sans-serif !important;
      }
    }
  }

  &.datetime-modal {
    &::part(content) {
      background-color: transparent;
    }

    .ion-page {
      background-color: transparent;
    }

    ion-datetime {
      border-radius: 10px;
    }
  }
}

.founder-modal {
  &::part(content) {
    background-color: transparent;
  }
}
.has-confetti:before {
  content: "";
  left: 0;
  width: 100%;
  height: 600%;
  background: url(assets/popups/confetti.svg) repeat-y center top;
  position: absolute;
  background-size: 100%;
  z-index: 4;
  animation: slide 60s linear infinite;
  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 33.3333%, 0);
    }
  }
  @media only screen and (orientation: landscape) {
    background: url(assets/popups/confetti.svg) repeat-y center top;
  }
}

.ios {
  .inline-modal {
    .ion-page {
      height: inherit;
    }
  }
}

.main-button {
  &::part(native) {
    background: rgb(0, 158, 150);
    background: linear-gradient(
      180deg,
      rgba(0, 158, 150, 1) 0%,
      rgba(0, 111, 105, 1) 100%
    );
  }
}

.alert-with-icons {
  .inline {
    display: inline-block;
  }

  .bigger {
    font-size: 18px;
  }

  .star-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    transform: translateY(1px);
    margin: 0;
  }

  .coin-icon {
    width: 15px;
    height: 15px;
    object-fit: contain;
    transform: translateY(1px);
    margin: 0;
  }

  &.send-stars {
    .alert-message {
      font-size: 17px !important;
      color: grey !important;

      .bigger {
        font-size: 22px;
        line-height: 40px;
        color: var(--black);
      }

      .star-icon {
        width: 20px;
        height: 20px;
        transform: translateY(0px);
      }

      .coin-icon {
        transform: translateY(1.5px);
      }
    }
  }
}

.native-alert {
  font-family: var(--ion-native-font-family);

  button {
    color: var(--ion-color-original-primary) !important; // Ionic primary color
  }
}

.contact-alert {
  // --background: var(--ion-color-darkteal);

  // * {
  // color: white
  // }

  .alert-head {
    display: block !important;
    text-align: center !important;
  }
  .alert-message {
    display: flex;
    color: #7c7c7c !important;
    font-size: 12px !important;
  }

  .alert-button-role-cancel {
    color: #7c7c7c !important;

    span {
      font-size: 13px !important;
    }
  }

  // ion-icon {
  //   display: block;
  //   width: 20px;
  //   flex-shrink: 0;
  //   margin-right: 10px;
  //   //color: var(--ion-color-original-primary);
  //   color: var(--white);
  // }
}

.locked-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 2;

  &.open {
    background-color: transparent;

    ion-icon {
      opacity: 0.6;
    }
  }

  ion-icon {
    color: var(--ion-color-darkteal);
    border: 2px solid var(--ion-color-darkteal);
    font-size: 30px;
    padding: 8px;
    background: var(--white);
    border-radius: 100%;
    box-shadow: 0 0 13px -3px var(--black);
  }
}

// Modals
.stars-modal,
.hands-modal,
.income-modal {
  &::part(content) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.stars-modal {
  &::part(content) {
    max-width: 800px;
    @media (min-width: 992px) and (hover: hover) {
      min-width: 800px;
    }
  }
}

.comments-modal {
  --max-height: calc(100% - env(safe-area-inset-top));

  &::part(content) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.comments-page-modal {
  &::part(content) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &::part(handle) {
    &::before {
      padding: 30px 150px;
    }
  }

  ion-content {
    height: 70vh;
  }
}

.description-modal {
  --max-height: 70%;
  color: #000000;

  &::part(content) {
    overflow: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: transparent;
    backdrop-filter: blur(15px);
  }

  .description {
    padding: 20px;
    padding-top: 35px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    white-space: break-spaces;
    color: #ffffff;
    font-size: 0.9rem;
  }
}

.viewers-modal,
.star-sender-list-modal {
  --max-height: 100%;

  .ion-page {
    // height: 100% !important;
  }

  &::part(content) {
    // height: 100%;
    overflow: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.report-modal {
  &::part(content) {
    overflow: visible;
  }
}

.no-ripple {
  --ripple-color: transparent;
}

.keyboard-space {
  height: 0px;
}

span.badge {
  padding: 0.3em 0.8em;
  border-radius: 100px;
  font-size: 0.7em;
  font-weight: 500;
  transform: translateY(-0.1em);
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
}


//Selectors for the video player controls (Old compatibility, deprecated soon)
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: var(--black) !important;
  // -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}


*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}



//Selectors for the video player controls (New changing selectors)
//https://github.com/w3c/csswg-drafts/issues/4805
input:state(webkit-autofill),
input:state(webkit-autofill:hover),
input:state(webkit-autofill:focus) input:state(webkit-autofill),
textarea:state(webkit-autofill),
textarea:state(webkit-autofill:hover) textarea:state(webkit-autofill:focus),
select:state(webkit-autofill),
select:state(webkit-autofill:hover),
select:state(webkit-autofill:focus) {
  border: none !important;
  -webkit-text-fill-color: var(--black) !important;
  // -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

*::state(webkit-media-controls-panel) {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}

*::state(webkit-media-controls-play-button) {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}

*::state(webkit-media-controls-play-button) {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}

*::state(webkit-media-controls-start-playback-button) {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}

video::state(webkit-media-controls-start-playback-button) {
  display: none !important;
  -webkit-appearance: none;
  appearance: none;
}


// @media (prefers-color-scheme: dark) {
body.dark {
  ion-skeleton-text {
    background-color: rgba(255, 255, 255, .2);
  }

  ion-item {
    --border-color: var(--ion-color-grey);
  }

  ion-datetime {
    --background: var(--ion-color-grey);
    --wheel-fade-background-rgb: 34, 34, 34;  // var(--ion-color-grey)
    --wheel-highlight-background: rgb(53, 53, 53);
    color: white;
  }

  ion-action-sheet {
    --background: var(--ion-color-grey) !important;
  }
}

/*
 * **********************
 */

.small-button {
  text-transform: none;
  font-size: 14px;
  --box-shadow: none;
  --border-radius: 15px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 15px;
  --padding-end: 15px;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
}

.small-button::part(native) {
  height: auto;
}

a {
  text-decoration: none;
}

.pb-40px {
  padding-bottom: 40px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-40px {
  margin-bottom: 40px;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mx-0 {
  margin-inline: 0;
}

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px !important;
}

.font-20px {
  font-size: 20px !important;
}

.font-32px {
  font-size: 32px;
}

table {
  width: 100%;
  text-align: left;

  &.bordered {
    border: 1px solid gray;

    td,
    th {
      border: 1px solid gray;
    }
  }

  td,
  th {
    padding: 10px !important;
  }

  th {
    background-color: #e5e5e5;
  }
}

app-element-factory {
  width: 100%;

  > div.entero {
    width: 100%;
  }
}

ion-header:not(.estrim-header)::after {
  background-image: none !important;
}

ion-header.estrim-header {
  ion-title {
    img,
    ion-icon {
      width: 125px;
      height: 27px;
      display: block;
    }
  }

  ion-buttons {
    ion-button {
      img {
        width: 35px;
        height: 32px;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ClashDisplay", serif;
  font-weight: 400;
}

p,
a {
  font-family: "ClashDisplay", serif;
  font-weight: 300;
}

small {
  font-family: "Helvetica", serif;
  font-weight: 300;
}

a:hover {
  opacity: 0.7;
}

#inbox-list {
  padding-top: 143px;
}

#inbox-list.ios {
  padding-top: 15px;
}

#closing-menu {
  width: 100%;
  position: relative;
  top: 47px;

  img {
    float: right;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 15px;
  }
}

.hidden {
  display: none !important;
}

ion-button.white-link {
  color: #2e2e2e;
  --background: var(--white);
  text-transform: none;
  font-family: "ClashDisplay", serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  --box-shadow: none;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-button.white-link:not(.bordered):hover,
ion-button.white-link.ion-activated,
ion-button.white-link:active,
ion-button.white-link:focus,
ion-button.white-link:visited {
  --background: rgba(32, 32, 32, 0.7) !important;
  --background-activated: rgba(32, 32, 32, 0.7) !important;
  color: var(--white);
}

ion-button.white-link.active {
  --background: #202020;
  color: var(--white);
}

.app-share-anchor {
  color: #7c7c7c;
  --color: #7c7c7c;
  --border-color: #7c7c7c;
  --border-radius: 50%;
  --border-width: 1px;
  --box-shadow: none;
  width: 20px;
  height: 20px;
  --width: 20px;
  --height: 20px;
  --background: transparent;
  --border-style: solid;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  display: table-cell;
  vertical-align: middle;

  &:hover {
    opacity: 0.7;
  }

  ion-icon {
    font-size: 14px;
  }
}

.social-share {
  .app-share-anchor {
    width: 40px;
    height: 40px;
    --width: 40px;
    --height: 40px;

    ion-icon {
      font-size: 28px;
    }
  }
}

.hexagon-image-cover {
  width: 50%;
  overflow: hidden;
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: none;
  margin: auto auto 50px;

  .hexagon-img-wrapper {
    width: 100%;
    height: auto;
    display: flex;
  }
}

.hexagon-image-cover.w80 {
  width: 80px;
}

.anchor-buttons-row {
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn-no-border {
  --border: none !important;
  --border-width: 0 !important;
}

.hexa-action-sheet {
  .action-sheet-group {
    .action-sheet-button-inner,
    .action-sheet-icon {
      color: var(--ion-color-secondary);
    }
  }
}

.action-sheet-button {
  font-family: "Helvetica", serif !important;
}

ion-header.head-transparent {
  position: absolute;
  --background: transparent !important;

  ion-toolbar {
    --background: transparent !important;

    ion-back-button {
      color: var(--ion-color-secondary);
      text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.3),
        1px -1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.3),
        1px 1px 1px rgba(0, 0, 0, 0.3);
    }
  }
}

.hexa-image-list {
  ion-item {
    --background: transparent !important;
    --padding-start: 0;
    --border-color: #6cc4ad !important;
    --border-width: 0 0 3px 0 !important;
    --inner-padding-end: 0;
    padding-bottom: 15px;

    ion-label h2 {
      text-transform: capitalize !important;
    }

    ion-avatar {
      width: 70px;
      height: 70px;

      .hexagon-image-cover {
        width: 100%;
      }
    }
  }

  &.home-image-list {
    ion-item {
      --border-width: 0 0 0 0 !important;
      padding-bottom: 0;
    }
  }
}

ion-slides.activity-slides {
  ion-slide {
    ion-card {
      width: 100%;

      ion-card-header {
        ion-card-title {
          font-size: 16px;
          font-family: "ClashDisplay", serif;
          text-align: left;

          ion-icon {
            float: right;
            position: relative;
            top: -2px;
            font-size: 25px;
          }
        }
      }

      .category-card-top {
        min-height: 150px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &.slide-disabled {
      ion-card {
        background: #e5e5e5;

        .category-card-top {
          filter: grayscale(100%);
        }

        ion-card-title {
          color: gray;

          ion-icon {
            display: none;
          }
        }
      }
    }
  }
}

ion-row.button-menu {
  margin-bottom: 15px;
}

ion-button.white-link.bordered {
  border: 1px solid #979797;
  background-color: var(--white);
  border-radius: 15px;
  font-family: "ClashDisplay", serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.05px;
  text-align: center;
  color: #2e2e2e;

  &.active,
  &:hover {
    border-color: #3aa09c;
    color: #37a09c;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.main-cover {
  padding-top: 27px;
  padding-bottom: 32.5px;
  padding-left: 27px;

  .go-back-button {
    margin: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    z-index: 9;
    position: absolute;
    top: 19px;
    right: 18px;
    display: none;
    cursor: pointer;
  }

  .go-back-button:hover {
    opacity: 0.7;
  }

  h1 {
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.15px;
    color: #0d0d0d;
    margin-bottom: 35px;
    margin-top: 14px;
  }

  h2 {
    width: 302px;
    height: 78px;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.1px;
    color: #0d0d0d;
    margin-top: 23px;
    margin-bottom: 20px;
  }

  ion-button.white-link {
    margin-right: 10px;
    margin-top: 0;
    height: 30px;

    &:last-child {
      margin-right: 0;
    }

    > a {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 70px;
    max-width: 400px;
  }

  ion-row.btn-row {
    margin-bottom: 15px;
  }

  ion-col.main-cover-side-img,
  ion-row.main-cover-below-img {
    padding-top: 96px;

    img {
      width: 216px;
      height: auto;
      margin-top: 0;
    }

    &.img-muerte-inesperada {
      img {
        width: 300px;
      }
    }

    &.img-comunicar {
      img {
        width: 301px;
      }
    }
  }

  ion-row.main-cover-below-img {
    padding-top: 68px;
  }

  ion-col.main-cover-side-img.img-muerte-inesperada {
    padding-top: 22px;

    img {
      width: 300px;
      height: auto;
    }
  }

  ion-col.main-cover-side-img.img-crisis-ansiedad {
    padding-top: 22px;

    img {
      width: 210px;
      height: auto;
    }
  }
}

.main-cover.second-cover {
  padding-right: 41px;
  padding-bottom: 20px;

  ion-row.btn-row {
    margin-bottom: 10px;
  }
}

.app-container {
  margin: auto;
}

.app-container:not(.home-page) {
  .main-cover {
    padding-bottom: 58px;

    h1 {
      margin-bottom: 44px;
    }

    ion-row.btn-row {
      margin-bottom: 25px;
    }

    ion-col.main-cover-side-img {
      padding-top: 60px;

      img {
        width: 332px;
        height: auto;
        margin-top: 0;
      }
    }

    ion-col.main-cover-side-img.img-muerte-inesperada {
      padding-top: 80px;
      padding-bottom: 14px;

      img {
        width: 382px;
      }

      &.img-comunicar {
        padding-top: 104px;
        padding-bottom: 6px;

        img {
          width: 402px;
        }
      }

      &.img-crisis-ansiedad {
        padding-top: 75px;
        padding-bottom: 17px;

        img {
          width: 289px;
        }
      }
    }
  }

  .main-cover.second-cover {
    position: relative;
    padding-top: 17px;
    padding-left: 40px;
    padding-bottom: 7px;

    ion-row.btn-row {
      margin-bottom: 15px;
    }

    ion-col.main-cover-side-img {
      padding-top: 48px;

      img {
        width: 216px;
        height: auto;
        margin-top: 0;
      }

      &.img-muerte-inesperada {
        img {
          width: 300px;
        }

        &.img-crisis-ansiedad {
          padding-top: 40px;

          img {
            width: 250px;
          }
        }
      }
    }
  }
}

div.footer {
  margin-top: 70px;

  h3 {
    font-family: "ClashDisplay", serif;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.06px;
    text-align: left;
    margin-bottom: 25px;
  }

  p {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    text-align: left;

    a {
      text-decoration: none;
      color: #2e2e2e;
      text-align: left;
    }
  }

  div.social {
    margin-bottom: 15px;
    text-align: center;

    a {
      text-align: center;

      &:hover {
        opacity: 0.7;
      }

      img {
        width: 38px;
        height: 38px;
        text-align: center;
      }
    }

    a:not(:last-child) {
      margin-right: 10px;
    }
  }

  small {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    color: #9f9f9f;
  }
}

.section-content {
  padding-left: 40px;
  padding-right: 40px;

  h2 {
    margin-top: 44px;
    margin-bottom: 27px;
    font-family: "ClashDisplay", serif;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.11px;
    color: #2e2e2e;
  }

  p {
    font-family: "ClashDisplay", serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.05px;
    color: #1e1e1e;
  }

  ul,
  ol {
    padding-left: 0;
    list-style-position: inside;

    li,
    li > span,
    li > p,
    li > a {
      font-family: "ClashDisplay", serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: -0.05px;
      color: #1e1e1e;

      ul {
        padding-left: 15px;
      }
    }
  }

  > ion-row {
    > ion-col {
      display: flex;

      > app-element-factory {
        display: flex;
      }

      .mitad.first {
        padding-right: 103.5px;
      }

      .mitad.last {
        padding-left: 103.5px;
      }
    }
  }
}

.activity-content {
  ul {
    margin-left: 0;
    text-align: left;
    padding-left: 15px;
  }
}

.hidden-md {
  display: none;
}

.block-text-element {
  margin-bottom: 13px;
  margin-top: 13px;

  h2 {
    font-family: "ClashDisplay", serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: -0.06px;
    color: #1e1e1e;
    margin-bottom: 15px;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05px;
    color: #2e2e2e;
  }
}

.informative-note-element > app-element-factory > div {
  border: 2px solid #dcdcdc;
  background: #dcdcdc;
  width: 341px;
  border-radius: 2px;
  padding: 20px;
  align-self: flex-end;
  margin-bottom: 124px;

  h3 {
    padding: 0;
    margin: 0 0 15px;
    font-family: "ClashDisplay", serif;
    font-weight: 500;

    ion-icon {
      position: relative;
      top: 4px;
    }
  }
}

.list-element {
  margin-bottom: 40px;
  margin-top: 40px;

  h2 {
    margin-top: 0;
  }

  ul,
  ol {
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: 25px;

      > p {
        margin: 0;
      }
    }
  }
}

.video-element {
  margin-top: 25px;
  margin-bottom: 25px;

  h2 {
    margin-bottom: 30px;
    margin-top: 0;
  }

  .video-wrapper {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 414px;
    height: 240px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 0 8px 6px 21px;
    overflow: hidden;

    h3 {
      font-family: "ClashDisplay", serif;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: -0.07px;
      color: var(--white);
      max-width: 235px;
      margin-top: 120px;
    }

    .video-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: 21px;
      padding-right: 8px;

      > p {
        float: left;

        > small {
          font-family: "ClashDisplay", serif;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.04px;
          color: var(--white);
        }
      }

      > img {
        float: right;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      > ion-fab {
        top: 10px;

        > ion-fab-list {
          top: -5px;
        }
      }
    }
  }
}

.audio-element {
  margin-top: 25px;
  margin-bottom: 25px;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  h3 {
    font-family: "ClashDisplay", serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.05px;
    color: #2e2e2e;
    margin-bottom: 20px;
    position: relative;

    > ion-fab {
      top: auto;

      > ion-fab-button {
        margin-top: 0 !important;
      }
    }
  }
}

.link-element {
  margin-top: 25px;
  margin-bottom: 25px;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }

  a.link-wrapper {
    text-decoration: none;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 414px;
    height: 240px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 0 8px 6px 21px;
    overflow: hidden;

    h3 {
      font-family: "ClashDisplay", serif;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: -0.07px;
      color: var(--white);
      max-width: 235px;
      margin-top: 110px;
    }

    .link-footer {
      margin-top: -12px;
    }
  }
}

.anchor-content-element {
  h3 {
    font-family: "ClashDisplay", serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.09px;
    color: #2e2e2e;
  }

  p {
    font-family: "ClashDisplay", serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.05px;
    color: #1e1e1e;
  }

  .anchor-content-single:not(:last-child) {
    margin-bottom: 40px;
  }

  ul,
  ol {
    li {
      margin-bottom: 25px;
      font-family: "ClashDisplay", serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: -0.05px;
      color: #1e1e1e;
    }
  }
}

.section-content {
  ul,
  ol {
    > li {
      span > p {
        display: inline;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ion-fab.share-anchor {
      > ion-fab-button {
        width: 20px;
        height: 20px;
        margin-top: 11px;
        border: 1px solid #7c7c7c;
        border-radius: 50%;
        --box-shadow: none;

        ion-icon {
          font-size: 14px;
          color: #4c4c4c;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

/*
button.button-native ion-icon.close-icon {
  --width: 20px !important;
  --height: 20px !important;
  --top: -1px !important;
  --right: -1px !important;
}*/

ion-fab.share-anchor {
  &.share-20 {
    > ion-fab-button {
      width: 23px;
      height: 23px;
      border: 1px solid var(--white);
      border-radius: 50%;
      --box-shadow: none;

      > ion-icon {
        font-size: 14px;
        color: var(--white);
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.social-share {
  border-top: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ion-fab {
    position: absolute;
    left: 15px;

    > ion-fab-button {
      --box-shadow: none;
      border: 1px solid #979797;
      border-radius: 50%;
      color: #2e2e2e;

      &:hover {
        background-color: rgba(32, 32, 32, 0.7) !important;
        color: var(--white);
      }
    }
  }

  ion-button.white-link.bordered {
    margin-left: 15px;
  }
}

#scroll-top {
  position: fixed;
  bottom: 15px;
  right: 0;
  z-index: 20;

  &:hover {
    opacity: 0.7;
  }

  > img {
    margin-right: 15px;
    cursor: pointer;
  }
}

ul.items-clickable {
  li {
    cursor: pointer;
  }
}

ion-content.video-modal,
main,
#background-content,
app-video-player,
.modal-wrapper {
  background: transparent !important;
  box-shadow: none !important;
}

.show-modal:not(.login-modal),
.show-modal:not(.login-modal) ion-backdrop {
  // background: #6a6a6a61;
}

.show-modal.login-modal,
.show-modal.login-modal ion-backdrop {
  background: rgba(0, 0, 0, 0.8);
}

.show-modal.wide-modal {
  .modal-wrapper {
    width: 764px;
  }
}

.legal-text-page {
  h1 {
    font-size: 32px;
    line-height: 36px;
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
  }

  h3 {
    font-family: "ClashDisplay", serif;
    font-stretch: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1e1e1e;
    font-weight: 500;
    letter-spacing: normal;
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

/*
span.label, ion-label.label {
  background: gray;
  font-size: 12px;
  padding: 5px 15px 4px;
  margin: 0;
  color: var(--white);
  border-radius: 2px;

  &.label-new {
    background: var(--ion-color-new);
    color: var(--ion-color-dark);
  }
}*/

.professional-card {
  cursor: pointer;
  box-shadow: 0 2px 10px 0 rgba(128, 128, 128, 0.5);
  border-radius: 9px;

  .professional-card-top {
    ion-card-title {
      position: absolute;
      bottom: 22px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      color: var(--white);
      font-family: "ClashDisplay", serif;
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.21;
      letter-spacing: 2.76px;
    }

    .favorites {
      position: absolute;
      top: 23px;
      right: 15px;
    }
  }

  .professional-rating {
    position: absolute;
    top: -12px;
    background: var(--white);
    z-index: 10;
    padding: 5px 10px;
    border-radius: 9px;
  }

  ion-card-subtitle {
    font-family: "ClashDisplay", serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
    color: var(--black);
  }

  ion-card-content {
    p {
      font-family: "ClashDisplay", serif;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 4.82;
      letter-spacing: 0.33px;
      color: var(--black);
    }
  }
}

.professional-card-top {
  position: relative;
  height: 275px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .professional-available {
    position: absolute;
    top: 23px;
    left: 15px;
    padding: 5px 8px;
    border-radius: 14px;
    background-color: var(--white);

    ion-icon {
      color: var(--ion-color-available);
    }
  }
}

.professional-labels {
  ion-label.label {
    margin-right: 13px;
    border-radius: 4px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: #e1edef;
    font-family: "ClashDisplay", serif;
    font-size: 10px;
    letter-spacing: 1.8px;
    text-align: center;
    color: #064958;
    display: inline-flex;
    margin-bottom: 15px;

    &:first-letter {
      letter-spacing: -1.8px;
    }
  }
}

.professional-langs {
  display: block;
  clear: both;
  width: 100%;

  ion-img {
    width: 25px;
    height: auto;
    margin-right: 5px;
    display: inline-block;
  }
}

.psychologist-list {
  h1 {
    height: 68px;
    font-family: "ClashDisplay", serif;
    font-size: 55px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.18px;
    color: var(--ion-color-dark);
  }

  h3 {
    height: 42px;
    font-family: "ClashDisplay", serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.07px;
    color: #111111;
  }
}

/*
ion-button.main-button {
  height: 51px;
  margin-top: 42px;
  border-radius: 3px;
  --background: linear-gradient(to bottom, #5bae8d, #3cb579);
  --box-shadow: none;
  font-family: 'ClashDisplay', serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.33px;

  > span {
    padding-left: 45px;
    padding-right: 45px;
  }
}*/

ion-button {
  ion-spinner {
    position: absolute;
    right: 5px;
  }
}

/*
.user-register {
  ion-button.main-button {
    width: 100%;
  }
}*/

.k-divider {
  position: relative;
  width: 100%;
  margin: 10px auto;

  ion-item-divider {
    border-color: #979797;
    padding: 0;

    span {
      position: absolute;
      bottom: -8px;
      width: 2%;
      text-align: center;
      background: var(--white);
      margin-right: 49%;
      margin-left: 49%;
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.form-link {
  a {
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: var(--ion-color-dark);
  }
}

app-form-input {
  &.uncoloured {
    ion-label,
    ion-checkbox {
      &.k-invalid {
        border-color: var(--ion-color-danger);
        border-width: 2.5px;
      }

      &.k-valid {
        border-width: 2.5px;
        --checkmark-color: var(--black);
        --border-color-checked: var(--black);
        color: var(--black);
      }
    }
  }
}

ion-textarea {
  height: auto;
  --background: var(--white) !important;
}

ion-item > ion-label {
  white-space: normal !important;
}

form {
  ion-label {
    --font-size: 14px;
    --font-weight: 500;

    &.k-invalid {
      --color: var(--ion-color-danger) !important;
    }

    &.k-valid {
      --color: var(--ion-color-success) !important;
    }

    a {
      color: inherit;
    }
  }
}

ion-checkbox {
  --padding: 2px !important;
  --padding-start: 0;
  --border-color: #c1c1c1;
  margin-inline-end: 10px;

  &.k-invalid {
    --border-color: var(--ion-color-danger);
    border-width: 2.5px;
    box-shadow: 0 0 5px 0 #eb9696;
  }

  &.k-valid {
    --border-color-checked: var(--ion-color-success);
    --checkmark-color: var(--ion-color-success);
    --checkbox-background-checked: transparent !important;
  }
}

/*
app-in-footer {
  z-index: 99999;
}*/

.error-message {
  display: block;

  small {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  ion-icon {
    margin-right: 5px;
  }
}

ion-item.form-error {
  --min-height: auto;
  --padding-start: 0;
  --padding-end: 0;
  margin-top: 5px;

  small {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.25px;
    display: block;
  }
}

ion-item.form-field {
  margin-top: 5px;
  --padding-start: 0;
  --padding-end: 0;

  ion-input,
  ion-label {
    --min-height: 0 !important;
  }
}

ion-button.bt-facebook,
ion-button.bt-google {
  width: 100%;
  font-family: "ClashDisplay", serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.33px;
  text-align: center;
  text-transform: none;
  height: 51px;
  --box-shadow: none;
  --border-bottom: 4px solid #325290;
}

ion-button.bt-google {
  --border-bottom: 4px solid #b72d2d;
}

app-show-hide-password {
  width: 100%;
}

.ion-no-padding-left {
  padding-left: 0 !important;
}

.ion-no-padding-right {
  padding-right: 0 !important;
}

.profile-info-box {
  padding: 35px 44px 120px 32px;

  h1 {
    font-family: "ClashDisplay", serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
  }
}

div.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #c6c8ca;
  border-radius: 0.25rem;
  color: #1b1e21;
  background-color: #d6d8d9;

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

ion-item.bordered {
  border: solid 1px #c6c6c6;
  padding: 23px;
  --padding-start: 0;
  --min-height: auto;

  p {
    margin: 0;
  }
}

@media screen and (max-width: 1176px) {
  .main-cover {
    > ion-row {
      width: 85%;
      margin: auto;
    }
  }

  .legal-text-page {
    padding-left: 21px;
    padding-right: 21px;
  }
}

@media screen and (max-width: 1036px) {
  .main-cover {
    > ion-row {
      width: 100%;
      margin: auto;
      display: inline-block;

      > ion-col {
        width: 40%;
        float: left;
      }

      > ion-col:first-child {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #closing-menu {
    top: 15px;

    img {
      margin-right: 10px;
    }
  }

  /*ion-header > ion-toolbar {
    padding-top: 20px !important;
    padding-bottom: 11px;

    > ion-title {
      > img {
        width: 204px;
        height: auto;
      }
    }
  }*/
  .main-cover {
    padding-right: 0 !important;
    padding-left: 0 !important;

    > ion-row {
      > ion-col {
        > ion-row:not(.main-cover-below-img) {
          padding-left: 20px !important;
        }
      }
    }

    .go-back-button {
      display: block;
    }

    > ion-row {
      display: flex;
    }

    h1,
    h2 {
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.1px;
      color: #0d0d0d;
      margin-bottom: 20px !important;
      margin-top: 13px;
    }

    &.bg-home-image {
      padding-left: 20px !important;
      padding-right: 20px !important;
      max-width: 100%;

      ion-row {
        max-width: 100%;
        width: 100%;

        h1 {
          font-size: 30px;
        }

        p {
          font-size: 20px;
          max-width: 100%;
          margin-bottom: 50px;
        }
      }
    }
  }
  .hidden-xs {
    display: none;
  }
  .hidden-md {
    display: block;
  }
  ion-row.btn-row {
    margin-bottom: 15px !important;
  }
  ion-button.white-link {
    font-size: 13px !important;
  }
  div.footer {
    padding-left: 20px;

    h3,
    p,
    a {
      text-align: left;
    }

    div.social {
      text-align: left;
    }
  }

  .title-element,
  .list-element,
  .block-text-element,
  .informative-note-element,
  .anchor-content-element,
  .audio-element {
    padding-left: 21px !important;
    padding-right: 21px !important;
    margin-bottom: 25px;
  }

  .link-element,
  .video-element,
  .audio-element {
    h2 {
      padding-left: 21px !important;
      padding-right: 21px !important;
    }
  }

  .informative-note-element > app-element-factory,
  .informative-note-element > app-element-factory > div {
    max-width: 100% !important;
    width: 100% !important;
    flex: none !important;
    display: block !important;
    margin: 0 !important;
    background: #dcdcdc;
  }

  .informative-note-element {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .section-content > ion-row > ion-col .mitad.first,
  .section-content > ion-row > ion-col .mitad.last {
    padding: 0;
  }

  .section-content {
    .video-element,
    .audio-element,
    .link-element {
      .mitad {
        max-width: 100%;
      }
    }
  }

  .list-element,
  .anchor-content-element,
  .link-element,
  .video-element {
    width: 100% !important;
    max-width: 100% !important;
    display: block !important;
    flex: none !important;
  }

  ul,
  ol,
  ul > li:last-child,
  ol > li:last-child {
    margin-bottom: 0;
  }

  .section-content {
    p,
    a,
    span,
    li,
    li > span,
    li > p,
    li > a {
      font-size: 18px !important;
      line-height: 25px !important;
    }
  }
}

.conference {
  position: relative;
  min-height: 500px;
  background-color: var(--black);

  #localStream {
    position: relative;
    z-index: 5;

    video {
      position: absolute;
      top: 15px;
      right: 15px;
      height: 15vh;
      max-height: 15vh;
      width: auto;
    }
  }

  #remoteStream {
    background: var(--black);
    text-align: center;
    height: 100vh;
    max-height: 100vh;

    video {
      height: 100vh;
      max-height: 100vh;
      width: auto;
      max-width: 100%;
    }
  }

  .video-options {
    position: absolute;
    bottom: 50px;
    width: 100%;
    margin: auto;
    text-align: center;

    img {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 38px;
      }
    }
  }

  #session-info-display {
    position: absolute;
    text-align: center;
    margin: auto;
    color: var(--white);
    width: 100%;
    padding-top: 15px;
    z-index: 10;

    p {
      margin-top: 0;
      margin-bottom: 5px;
      font-family: "Roboto", serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      color: var(--white);
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

      &.time-count {
        font-size: 15px;
        font-weight: normal;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .section-content {
    padding-left: 0;
    padding-right: 0;
  }

  .video-element {
    padding: 0;

    h2 {
      padding-left: 21px;
    }

    .video-wrapper {
      margin-right: 0;
      width: 100%;
    }
  }

  .audio-element {
    padding-left: 21px;
  }

  .link-element {
    padding: 0;

    h2 {
      padding-left: 21px;
    }

    > div {
      width: 100%;

      .link-wrapper {
        width: 100%;
      }
    }
  }

  ion-row.button-menu {
    margin-bottom: 0;
  }

  .anchor-content-element h3 {
    font-size: 22px;
  }
}

ion-back-button,
.cancel-button {
  position: absolute;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ion-spinner {
    width: 70px !important;
    height: 70px !important;
  }
}

.soft-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgb(0 0 0 / 15%);

  ion-spinner {
    width: 70px !important;
    height: 70px !important;
  }
}

// .publication-thumbnail {
//   width: 100%;
//   height: 100%;
//   aspect-ratio: 16/9;
//   object-fit: contain;
//   background: var(--black);
// }

.video-thumbnail {
  width: 100%;
  border-radius: 20px;
  aspect-ratio: 16/9;
  object-fit: none;
  background: var(--black);
}

.event-thumbnail {
  width: 100%;
  border-radius: 20px;
  object-fit: contain;
  background: var(--black);
}

.primary-color {
  color: var(--ion-color-primary) !important;
}

.small-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.small-modal .modal-wrapper {
  width: 90%;
  height: 80%;
  border-radius: 20px;
}

.xsmall-modal .modal-wrapper {
  width: 90%;
  height: 8%;
  padding: 10px;
}

ion-backdrop {
  background: #000000;
}

.div-separator {
  background: var(--lightgray3);
  height: 2px;
  margin: 0 -500px;
  margin-top: 15px;

  &.thin {
    height: 3px;
    margin-top: 0px;
  }
}

ion-button {
  font-family: "Aeonik", serif;
}

.display-flex {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
}

.fill-space {
  /*height: 11vh;*/
  height: 6vh;
  background: var(--white);
}

.text-clamp {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  clear: both;
  display: block;
}

ion-button.action-button {
  font-family: ClashDisplay, Serif !important;
  --padding-top: 15px;
  --padding-bottom: 15px;
}

ion-app {
  background: var(--white) !important;

  app-k-header {
    background: var(--white) !important;

    ion-header {
      background: var(--white) !important;
    }
  }
}

.video-container,
.audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  min-height: 35vh;
  max-height: 50vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-color: var(--black);

  &.fullscreen-mode {
    height: 100vh;
    max-height: unset;
    z-index: 99999;
  }

  &.audioFormat {
    height: 35vh;
  }

  &.emitting:not(.audioFormat) {
    min-height: 198px;
    //max-height: 309px;
  }

  #startEmitting,
  #stopEmitting {
    &.emitting {
      position: absolute;
      right: 3%;
      bottom: 3%;
      font-size: 15px;
      --padding-top: 1px;
      --padding-bottom: 1px;
      --padding-start: 6px;
      --padding-end: 6px;
    }
  }

  //video {
  //  object-fit: cover;
  //  background: var(--black);
  //}
  //
  //video:first-child:nth-last-child(1) {
  //  /* -or- video:only-child { */
  //  width: 100%;
  //}
  //
  ///* two items */
  //video:first-child:nth-last-child(2),
  //video:first-child:nth-last-child(2) ~ video {
  //  width: 100%;
  //  height: 25vh;
  //}
  //
  ///* three items */
  //video:first-child:nth-last-child(3),
  //video:first-child:nth-last-child(3) ~ video {
  //  width: 50%;
  //  height: 25vh;
  //}
  //
  //video:nth-child(3):nth-last-child(1) {
  //  width: 100% !important;
  //}
  //
  ///* four items */
  //video:first-child:nth-last-child(4),
  //video:first-child:nth-last-child(4) ~ video {
  //  width: 50%;
  //  height: 25vh;
  //
  //  video:first-child:nth-last-child(3) {
  //    width: 50%;
  //  }
  //}
}

@media only screen and (orientation: landscape) {
  .video-container {
    max-height: 100vh;
    height: 100vh;

    &.audioFormat {
      height: 100vh !important;
    }

    //video {
    //  object-fit: cover;
    //  background: var(--black);
    //}

    //video:first-child:nth-last-child(1) {
    //  /* -or- video:only-child { */
    //  width: 100%;
    //  height: 100vh;
    //}
    //
    ///* two items */
    //video:first-child:nth-last-child(2),
    //video:first-child:nth-last-child(2) ~ video {
    //  width: 100%;
    //  height: 50vh;
    //}
    //
    ///* three items */
    //video:first-child:nth-last-child(3),
    //video:first-child:nth-last-child(3) ~ video {
    //  width: 50%;
    //  height: 50vh;
    //}
    //
    ///* four items */
    //video:first-child:nth-last-child(4),
    //video:first-child:nth-last-child(4) ~ video {
    //  width: 50%;
    //  height: 50vh;
    //
    //
    //}
  }

  #startEmitting,
  #stopEmitting {
    position: absolute;
    bottom: 5px;
    font-size: 13px;
    right: 5px;
    height: 30px;
  }
}

ion-refresher {
  display: flex !important;
}

app-live-thumbnail:hover,
app-audio-thumbnail:hover,
app-event-thumbnail:hover,
app-user-recommendation-thumbnail:hover,
app-one-video-thumbnail:hover,
app-one-audio-thumbnail:hover,
app-record-video-thumbnail:hover,
app-record-audio-thumbnail:hover,
#back-button:hover,
.seeAll:hover,
.followers-span:hover,
.collaborator-span:hover,
#biography-summary b:hover {
  cursor: pointer;
  // opacity: .8;
}

.swiper-events {
  .swiper-slide {
    width: 140px !important;
  }
}

@media (max-width: 479px) {
  .swiper-videos {
    .one-tag {
      span {
        font-size: 9px !important;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .swiper-videos {
    .one-tag {
      span {
        font-size: 11px !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .swiper-videos {
    .one-tag {
      span {
        font-size: 11px !important;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .swiper-videos {
    .one-tag {
      span {
        font-size: 12px !important;
      }
    }
  }
}

@media (min-width: 1281px) {
  .swiper-videos {
    .one-tag {
      span {
        font-size: 14px !important;
      }
    }
  }
}

.swiper-videos {
  .one-tag {
    span {
      font-size: 9px;
    }
  }
}

.title-thumbnail {
  font-size: 13px !important;
  font-weight: 600 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  margin-top: 5px !important;
  line-height: 15px;
}

.swiper-categories {
  .swiper-wrapper {
    //transform: translate3d(0px, 0, 0) !important;
  }

  .swiper-slide {
    width: auto !important;
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.ion-activatable.ripple-parent {
  cursor: pointer;
}

.video-container {
  background-size: cover;
  background-position: center center;

  .collaborator-videos {
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;

    &.landscape {
      position: absolute;
    }
  }

  .video-owner {
    width: 100%;
    max-height: 100%;
  }

  video:not(.video-owner) {
    width: 33.33%;
    object-fit: cover;
  }
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
  //--border-width: 0 0 0.55px;
}

//ion-icon {
//  pointer-events: none !important;
//}

.alert-button-dark .alert-button-inner {
  color: var(--ion-color-dark);
}

.alert-button-danger .alert-button-inner {
  color: var(--white);
}

.alert-button-success .alert-button-inner {
  color: var(--white);
}

.header-custom-button {
  position: absolute;
  z-index: 9;
  margin: 0;

  &::part(native) {
    overflow: visible;
    padding: 0;
    background: none;
  }

  ion-icon {
    filter: drop-shadow(3px 3px 5px black);
  }

  &.back-button {
    top: 1%;
    left: 4%;

    ion-icon {
      font-size: 30px;
    }
  }
}

.no-margin-button {
  margin: 0;

  ion-icon {
    width: 100%;
    height: 100%;
  }

  &::part(native) {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.my-video,
.their-video {
  video {
    background-size: contain;
    background-position: center center;
    width: 100%;
    object-fit: contain;
  }
}

ion-content.ios {
  // --padding-top: var(--ion-safe-area-top);

  // &.ion-padding-vertical, &.ion-padding{
  //   --padding-top: calc(16px + var(--ion-safe-area-top));
  // }

  .app-container {
    position: relative;
  }
}

ion-tab-bar.ios {
  padding-bottom: 0;
}

ion-tab-bar {
  border: none;
}

/*23/05/2022*/
.padding-active-swiper {
  // transform: scale(0.94);
}

.padding-active-swiper {
  .swiper-slide.swiper-slide-active {
    // transform: scale(1.07);
  }
}

.padding-active-swiper {
  .swiper-slide.swiper-slide-next {
    // opacity: 0.5;

    ion-col {
      // padding: 0;
    }
  }
}

.padding-active-swiper {
  .swiper-slide.swiper-slide-prev {
    // opacity: 0.5;

    ion-col {
      // padding: 0;
    }
  }
}

.edit-profile-items input {
  padding-bottom: 5px !important;
  font-family: Helvetica, serif !important;
  color: #797979 !important;
  font-size: 15px !important;
  padding-top: 15px !important;
}

.edit-profile-items ion-input {
  border-bottom: 2px solid var(--ion-color-grey) !important;
  border-width: 0px !important;
}

.edit-profile-items ion-datetime {
  border-bottom: 2px solid var(--ion-color-grey) !important;
  border-width: 0px !important;
}

.edit-profile-items ion-datetime {
  padding-bottom: 5px !important;
  font-family: Helvetica, serif !important;
  color: #797979 !important;
  font-size: 15px !important;
  padding-top: 15px !important;
}

.edit-profile-items ion-textarea {
  border-bottom: 2px solid var(--ion-color-grey) !important;
  border-width: 0px !important;
}

.edit-profile-items .item-inner {
  padding-right: 0 !important;
}

.modify-slider-salas-audio {
  .swiper-slide {
    margin-right: 13px !important;
  }

  ion-grid {
    padding: 0;
  }
}

.modify-slider-estrim-video-one {
  .swiper-slide {
    margin-right: 13px !important;
  }

  ion-grid {
    padding: 0;
  }

  ion-col {
    padding: 0;
  }

  .container-estrim-one {
    height: 83px !important;
  }
}

.modify-edit-events-virtual {
  .swiper-slide {
    margin-right: 13px !important;
  }

  ion-grid {
    padding: 0;
  }

  ion-col {
    padding: 0;
  }

  .container-estrim-one {
    height: 83px !important;
  }
}

.modific-estrenos-pro {
  .swiper-slide {
    margin-right: 13px !important;
  }

  ion-grid {
    padding: 0;
  }

  ion-col {
    padding: 0;
  }

  .container-estrim-one {
    height: 83px !important;
  }
}

.category-modify-app-new {
  .btn-category-modify {
    width: 100%;
    --background: var(--white);
    --border-radius: 12px;
    --box-shadow: 0px 0px 4px #0000004f;
    background-color: var(--white);
    border-radius: 8px;

    svg {
      height: 14.5px;
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 4px;
      position: absolute;
      left: 0;
    }

    span {
      position: absolute;
      font-size: 13px;
      left: 25px;
      color: var(--black);
      font-family: Helvetica, serif;
      letter-spacing: 0px;
    }
  }
}

.modific-for-you {
  padding: 0;

  ion-col {
    padding: 0;
  }
}

// .container-estrim-one {
//   border-radius: 11px;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   position: relative;

//   img {
//     width: 100%;
//     height: 100%;
//     aspect-ratio: 16/9;
//     object-fit: contain;
//   }

//   .one-container {
//     color: var(--black);
//     position: absolute;
//     z-index: 99;
//     right: 0;
//     bottom: 0;
//     display: flex;
//     align-items: flex-end;
//     font-size: 12px;
//     padding: 18%;
//     overflow: hidden;

//     &::after {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       border-radius: 0 0 100% 0;
//       width: 100%;
//       height: 100%;
//       box-shadow: 19px 20px 0px 2000px rgb(0 202 203);
//     }

//     .title {
//       z-index: 100;
//       position: absolute;
//       right: 3%;
//       bottom: 8%;
//       transform: rotate(-45deg);
//       font-size: 10px;
//       font-weight: 600;
//     }
//   }

//   .container-price {
//     color: var(--white);
//     background: rgba(0, 0, 0, 0.91);
//     position: absolute;
//     z-index: 99;
//     left: 0;
//     bottom: 0;
//     padding: 5px 9px 5px 9px;
//     line-height: 12px;
//     border-radius: 0 11px;
//     font-size: 12px;
//     font-family: Helvetica, serif;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .icon-round {
//       width: 10px;
//       height: 10px;
//       background-color: rgb(249 172 23);
//       border-radius: 100%;
//       margin-right: 8px;
//     }
//   }
// }

.container-profile-user-one {
  display: flex;
  margin-top: 8px;
  align-items: center;
  margin-bottom: 4px;

  .profile-img {
    width: 16.32px;
    min-width: 16.32px;
    position: relative;
    margin-right: 5px;
    height: 16.32px;

    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  .profile-name {
    label {
      font-family: Helvetica, serif;
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 4px;
      text-transform: capitalize;
    }
  }
}

.description-estrim-one {
  color: var(--black);
  font-size: 13px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Helvetica, serif;
}

.container-star-estrim {
  display: flex;
  align-items: center;
  margin-top: 3px;

  .valoration {
    span {
      margin-top: 2px;
      display: block;
      margin-right: -1px;
      font-family: Helvetica, serif;
      font-size: 14px;
    }
  }

  .container-star {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #d6913b;
      height: 12px;
      margin: 0px 1px;
    }
  }
}

.container-cant-valoration {
  span {
    margin-top: 0px;
    display: block;
    margin-right: 0;
    font-family: Helvetica, serif;
    font-size: 11px;
    color: var(--ion-color-medium);
  }
}

.no-margin-alert {
  .alert-wrapper {
    .alert-message.sc-ion-alert-md,
    .alert-message.sc-ion-alert-ios {
      font-family: Helvetica, serif !important;
      color: var(--black);
      margin: 0;
      text-align: center;
    }
  }
}

ion-alert:not(.native-alert) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif !important;

  .alert-wrapper {
    min-width: 420px !important;
    border-radius: 15px !important;
    background-color: var(--white);
    padding: 20px;

    .moneymoney {
      height: 14px;
      width: 14px;
      background-image: url(assets/coin.png);
      background-size: cover;
      display: inline-block;
    }

    .alert-head.sc-ion-alert-md,
    .alert-head.sc-ion-alert-ios {
      display: none;

      padding-left: unset;
      padding-right: unset;
      -webkit-padding-start: 23px;
      padding-inline-start: 23px;
      -webkit-padding-end: 23px;
      padding-inline-end: 23px;
      padding-left: 23px;
      padding-right: 23px;
      padding-top: 20px;
      padding-bottom: 15px;
      text-align: start;

      .alert-title {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .alert-message.sc-ion-alert-md,
    .alert-message.sc-ion-alert-ios {
      font-family: Helvetica, serif !important;
      color: var(--black);
      text-align: center;
      font-size: 18px;
      margin-bottom: 30px;
      margin-top: 20px;
      padding: 0 10px;
    }

    .alert-button-group.sc-ion-alert-md,
    .alert-button-group.sc-ion-alert-ios {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: column;
      padding: 0;

      button.sc-ion-alert-md,
      button.sc-ion-alert-ios {
        background-color: var(--ion-color-darkteal);
        border-radius: 8px;
        color: var(--white);

        &.alert-button-danger {
          background-color: var(--ion-color-danger);
        }

        &.alert-button-white {
          background-color: var(--white);
          color: var(--black);
        }

        .alert-button-success {
          .sc-ion-alert-md,
          .sc-ion-alert-ios {
            color: var(--white);
          }
        }
      }

      button.alert-button-role-cancel {
        background-color: var(--white);
        color: var(--black);
      }

      button {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 500;
        border: 0;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: inline-block;
          text-transform: initial;
          font-size: 18px;
          height: fit-content;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  ion-alert:not(.native-alert) {
    .alert-wrapper {
      min-width: 85% !important;
    }
  }
}

.search-content {
  .dividir-section {
    width: 100%;
    height: 1px;
    background-color: var(--lightgray3);
    margin-top: 33px;
    margin-bottom: 33px;
  }

  h1 {
    margin-top: 30px;
    text-align: left;
    margin-left: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 21px;
  }

  h1.view-all {
    justify-content: space-between !important;
    display: flex;
    margin-right: 20px;

    .small-view-title {
      font-size: 17px;
      color: #6c6c6c;
      font-weight: 500;
    }
  }

  .contact-list {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      display: flex;
      margin: 20px 19px;
      position: relative;
      align-items: center;

      .back-contact {
        width: 44px;
        height: 44px;
        background-color: #424242;
        border-radius: 100px;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .name-contact {
        margin-left: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-family: Helvetica, serif;
        width: 220px;
      }

      .sub {
        color: var(--ion-color-medium);
        margin-left: 13px;
        font-size: 14px;
      }

      ion-button {
        position: absolute;
        right: 0;
        --background: var(--ion-color-darkteal);
        --padding-start: 32px;
        --padding-end: 32px;
        --border-radius: 29px;
        --padding-bottom: 20px;
        --padding-top: 20px;
        width: 36%;
      }

      ion-button.active {
        --background: var(--lightgray3);
        color: var(--black);
      }
    }
  }

  .nav-items {
    margin-top: 10px;
    margin-bottom: 10px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--ion-color-grey);

      li {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: all 0.3s;
        padding-bottom: 8px;

        ion-button {
          position: relative;
          --padding-bottom: 0;
          --padding-top: 0;
          --border-radius: 10px;
          --background: var(--white);
          --padding-start: 20px;
          --padding-end: 20px;

          span {
            position: absolute;
          }
        }
      }

      li.active {
        &::before {
          content: " ";
          position: absolute;
          bottom: 0px;
          height: 7px;
          background-color: #343434;
          display: block;
          z-index: 1;
          width: 100%;
        }
      }
    }
  }

  .icon-nav {
    height: 40px;
    width: 40px;
    background-size: contain;
    display: inline-block;
    transition: all 0.3s;
    background-repeat: no-repeat;
  }

  .icon-nav.deporte {
    background-image: url(assets/svg-icon/deportes.svg);
  }

  .icon-nav.musica {
    background-image: url(assets/svg-icon/musica.svg);
  }

  .icon-nav.salud {
    background-image: url(assets/svg-icon/salud.svg);
  }

  .icon-nav.cine {
    background-image: url(assets/svg-icon/cine.svg);
  }

  .icon-nav.noticias {
    background-image: url(assets/svg-icon/noticias.svg);
  }

  .icon-nav.aprendizaje {
    background-image: url(assets/svg-icon/aprendizaje.svg);
  }

  .icon-nav.videojuegos {
    background-image: url(assets/svg-icon/videojuegos.svg);
  }

  .icon-nav.lifestyle {
    background-image: url(assets/svg-icon/lifestyle.svg);
  }

  .icon-nav.humor {
    background-image: url(assets/svg-icon/humor.svg);
  }

  .icon-nav.comunidad {
    background-image: url(assets/svg-icon/comunidad.svg);
  }

  .icon-nav.ciencia {
    background-image: url(assets/svg-icon/ciencia.svg);
  }

  .icon-nav.tecnologia {
    background-image: url(assets/svg-icon/tecnologia.svg);
  }

  .icon-nav.literatura {
    background-image: url(assets/svg-icon/literatura.svg);
  }

  .icon-nav.arte {
    background-image: url(assets/svg-icon/arte.svg);
  }

  .icon-nav.juegos {
    background-image: url(assets/svg-icon/juegos.svg);
  }

  .icon-nav.viajes {
    background-image: url(assets/svg-icon/viajes.svg);
  }

  .icon-nav.psicologia {
    background-image: url(assets/svg-icon/psicologia.svg);
  }

  .icon-nav.animales {
    background-image: url(assets/svg-icon/animales.svg);
  }

  .icon-nav.emprendimiento {
    background-image: url(assets/svg-icon/emprendimiento.svg);
  }

  .icon-nav.gastronomia {
    background-image: url(assets/svg-icon/gastronomia.svg);
  }

  .icon-nav.moda {
    background-image: url(assets/svg-icon/moda.svg);
  }

  .icon-nav.belleza {
    background-image: url(assets/svg-icon/belleza.svg);
  }

  .icon-nav.politica {
    background-image: url(assets/svg-icon/politica.svg);
  }

  .icon-nav.asmr {
    background-image: url(assets/svg-icon/asmr.svg);
  }

  .icon-nav.infantil {
    background-image: url(assets/svg-icon/infantil.svg);
  }

  .icon-nav.crypto {
    background-image: url(assets/svg-icon/crypto.svg);
  }

  .icon-nav.motor {
    background-image: url(assets/svg-icon/motor.svg);
  }

  .icon-nav.idioma {
    background-image: url(assets/svg-icon/idioma.svg);
  }

  .icon-nav.relaciones {
    background-image: url(assets/svg-icon/relaciones.svg);
  }

  .icon-nav.marketing {
    background-image: url(assets/svg-icon/marketing.svg);
  }

  .icon-nav.social {
    background-image: url(assets/svg-icon/social.svg);
  }

  .icon-nav.bricolaje {
    background-image: url(assets/svg-icon/bricolaje.svg);
  }

  .icon-nav.jardineria {
    background-image: url(assets/svg-icon/jardineria.svg);
  }

  .icon-nav.naturaleza {
    background-image: url(assets/svg-icon/naturaleza.svg);
  }

  .icon-nav.web {
    background-image: url(assets/svg-icon/web.svg);
  }

  .icon-nav.maternidad {
    background-image: url(assets/svg-icon/maternidad.svg);
  }

  .icon-nav.tarot {
    background-image: url(assets/svg-icon/tarot.svg);
  }

  .icon-nav.finanzas {
    background-image: url(assets/svg-icon/finanzas.svg);
  }

  .icon-nav.magia {
    background-image: url(assets/svg-icon/magia.svg);
  }

  .icon-nav.geografia {
    background-image: url(assets/svg-icon/geografia.svg);
  }

  .icon-nav.podcast {
    background-image: url(assets/svg-icon/podcast.svg);
  }

  .icon-nav.legal {
    background-image: url(assets/svg-icon/legal.svg);
  }

  .icon-nav.periodismo {
    background-image: url(assets/svg-icon/periodismo.svg);
  }

  .icon-nav.diy {
    background-image: url(assets/svg-icon/diy.svg);
  }

  .icon-nav.lgtbiq {
    background-image: url(assets/svg-icon/lgtbiq.svg);
  }

  .icon-nav.decoracion {
    background-image: url(assets/svg-icon/decoracion.svg);
  }

  .icon-nav.espectaculo {
    background-image: url(assets/svg-icon/espectaculo.svg);
  }

  .icon-nav.teatro {
    background-image: url(assets/svg-icon/teatro.svg);
  }

  .icon-nav.random {
    background-image: url(assets/svg-icon/random.svg);
  }

  .icon-nav.arquitectura {
    background-image: url(assets/svg-icon/arquitectura.svg);
  }

  .icon-nav.diseno {
    background-image: url(assets/svg-icon/diseno.svg);
  }

  .icon-nav.main {
    background-image: url(assets/svg-icon/main.svg);
  }

  .icon-nav.user {
    background-image: url(assets/svg-icon/user.svg);
  }

  .icon-nav.camera {
    background-image: url(assets/svg-icon/camera.svg);
  }

  .icon-nav.camera {
    background-image: url(assets/svg-icon/camera.svg);
  }

  .icon-nav.micro {
    background-image: url(assets/svg-icon/micro.svg);
  }

  .icon-nav.ticket {
    background-image: url(assets/svg-icon/ticket.svg);
  }

  .icon-nav.calendar {
    background-image: url(assets/svg-icon/calendar.svg);
  }

  .icon-nav.one {
    background-image: url(assets/svg-icon/one.svg);
  }
}

.owner-videos.maximized > video {
  //height: unset !important;
}

// .collaborator-videos {
//   video {
//     width: 33.33%;
//     aspect-ratio: 16/9;
//     transform: translateY(5px);
//   }

//   &.maximized {
//     video {
//       width: auto;
//     }
//   }
// }

.need-to-be-pro-dialog {
  .alert-wrapper {
    padding: 20px 10px 10px 0;

    .alert-title {
      font-family: "Helvetica", sans-serif;
      text-align: center;
      font-weight: bold;
    }

    .alert-message {
      margin: 0;
    }
  }
}

.no-max-height {
  max-height: auto;
}

.my-wallet-alert {
  h2.alert-title {
    text-align: center;
    font-weight: bold;
    font-family: "Helvetica Neue Roman";
    font-size: 18px;
  }

  .alert-wrapper {
    .alert-head {
      margin: 10px;
      font-family: "Helvetica Ultra Roman";
    }

    .alert-message.sc-ion-alert-md {
      color: #7a7a7a;
      letter-spacing: 0.2px;
      font-size: 16px;
      font-family: "Helvetica Neue Ultra Light";
      --ion-font-family: "Helvetica Neue Ultra Light";
    }

    .alert-button-group.sc-ion-alert-md {
      button {
        span {
          font-family: "Helvetica Neue Light";
          --ion-font-family: "Helvetica Neue Light";
        }
      }
    }
  }
}

.no-data {
  padding: 0 20px;
  margin: 30px 0px;
  display: block;
  text-align: center;
  font-family: "Helvetica", serif;
  color: #919191;
}

@media only screen and (orientation: landscape) {
  .private-other-videos {
    video {
      width: 100%;
    }
  }
}

.private-own-videos {
  video {
    background-color: var(--black);
    position: absolute;
    right: 8px;
    bottom: 8px;
    max-height: 30%;
    max-width: 50%;
    /*transform: rotateY(180deg); Request for Samuel And Antonio */
    transform: unset !important;
    transition: all 0.5s ease-in-out;
    z-index: 1;
  }

  &.justMe {
    video {
      background-color: var(--black);
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      height: 100%;
      /*transform: rotateY(180deg); Request for Samuel And Antonio */
      transform: unset !important;
      max-height: unset;
      max-width: unset;
      transition: all 0.5s ease-in-out;
      object-fit: cover;
      width: 100%;
    }
  }
}

.private-other-videos {
  video {
    background-color: var(--black);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.wide-alert {
  .alert-message {
    margin: 0 !important;
  }
}

//COOKIES
#CybotCookiebotDialog {
  left: 15px !important;
  max-width: 300px !important;
  transform: unset !important;
  bottom: 0 !important;
  background-color: var(--white) !important;
  border-radius: 8px !important;
  box-shadow: 0 32px 68px rgb(0 0 0 / 30%) !important;
  box-sizing: border-box !important;
  color: #141414 !important;
  font-family: sans-serif !important;
  font-size: 15px !important;
  height: auto !important;
  letter-spacing: 0.1px !important;
  line-height: 24px !important;
  max-height: calc(100% - 16px) !important;
  overflow: hidden !important;
  position: fixed !important;
  text-align: initial !important;
  top: unset !important;
  transition: all 0.5s ease !important;
  transition-property: width, max-width, top, bottom, left, opacity !important;
  width: calc(100% - 16px) !important;
  z-index: 2147483631 !important;
  min-width: unset !important;
}

.CybotCookiebotDialogBodyButton {
  font-size: 12px !important;
  padding: 5px !important;
  width: fit-content !important;
  height: fit-content !important;
}

.CybotCookiebotDialogContentWrapper {
  flex-wrap: wrap !important;
}

#CybotCookiebotDialogTabContent {
  margin: 0 !important;
}

#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogHeader,
#CybotCookiebotDialogBodyEdgeMoreDetails {
  display: none !important;
}

#CybotCookiebotDialogFooter {
  width: 100% !important;
}

.termsLink {
  font-family: "Helvetica", serif;
  color: var(--ion-color-darkteal);
}

video.girated {
  transform: scaleX(-1) !important;
}

.container-profile-center {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 5px;
  height: auto;

  .profile-img {
    img {
      width: 19px;
      min-width: 19px;
      height: 19px;
      --border-radius: 50%;
      --size: 100%;
      border: 1px solid var(--ion-color-img-border);
    }
    ion-thumbnail {
      width: 19px;
      height: 19px;
    }
  }

  .profile-name {
    display: flex;
    align-items: center;
    font-family: Helvetica, serif;
    font-size: 12.5px;
    font-weight: 400;
    text-transform: capitalize;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .founder-badge {
      width: 13px;
      height: 13px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

/******* 2023 REDESIGN *************/

.flex {
  display: flex;
}
.block {
  display: block;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.text-white {
  color: var(--white);
}
.aspect-video {
  aspect-ratio: 16/9;
}
.thumb-aspect-video {
  .thumb-wrapper {
    aspect-ratio: 16/9;
  }
}
.aspect-square {
  aspect-ratio: 1/1;
}
.rounded {
  border-radius: 0.25rem;
  overflow: hidden;
}
.rounded-full {
  border-radius: 9999px;
}
.capitalize-first-letter {
  &::first-letter {
    text-transform: uppercase;
  }
}
.w-full {
  width: 100%;
}
.text-gray {
  color: var(--gray, #9f9f9f);
}
.text-darkgray {
  color: var(--darkgray, var(--ion-color-medium));
}
.text-teal {
  color: var(--ion-color-teal);
}
.text-darkteal {
  color: var(--ion-color-darkteal);
}
.text-smaller {
  font-size: 0.8em;
}
.p-0 {
  padding: 0 !important;
}
.px {
  padding-left: 12px;
  padding-right: 12px;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-1 {
  padding-top: 0.7em;
}
.pb-1 {
  padding-bottom: 0.7em;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-05 {
  margin-top: 0.35em;
}
.mb-05 {
  margin-bottom: 0.35em;
}
.mt-1 {
  margin-top: 0.7em;
}
.mb-1 {
  margin-bottom: 0.7em;
}
.mr-1 {
  margin-right: 0.7em;
}
.ml-1 {
  margin-left: 0.7em;
}
.mb-2 {
  margin-bottom: 1.4em;
}
.mb-3 {
  margin-bottom: 2.1em;
}
.mb-4 {
  margin-bottom: 2.8em;
}
.pr-1 {
  padding-right: 0.7em;
}
.font-medium {
  font-weight: medium;
}
.font-bold {
  font-weight: bold;
}
.inline-block {
  display: inline-block !important;
}
.has-separators {
  padding-top: 0.7em;
}
.has-separators > * + * {
  padding-top: 10px;
  margin-top: 30px;
  border-top: 1px solid var(--lightgray2);
}
.inline-dot-list {
  > *:not(:last-child):after {
    content: "·";
    margin: 0 0.25em;
  }
}
.auto-grid {
  --auto-grid-min-size: 20.18em;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 0px 15px;
  padding: 15px;
  &.videos-grid {
    grid-gap: 15px;
  }
  &.events-grid {
    grid-gap: 15px 0;
    app-event-thumbnail {
      padding-inline: 0 !important;
    }
  }
  &.podcasts-grid {
    --auto-grid-min-size: 190px;
    padding: 5px 15px;
    grid-gap: 15px;
  }
  &.clips-grid {
    --auto-grid-min-size: 14.18em;
    grid-gap: 15px;
  }
  &.audio-rooms-grid {
    --auto-grid-min-size: calc(25% - 30px);
    padding: 5px 15px;
    grid-gap: 15px;
  }
  &.audio-rooms-large-grid {
    --auto-grid-min-size: 22.18em;
    padding: 15px 15px;
    grid-gap: 15px;
  }
  &.medium-size-grid {
    --auto-grid-min-size: 15em;
    grid-gap: 15px;
  }
  &.centered-grid {
    max-width: 890px;
    margin: 30px auto;
  }
}
.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  &.line-clamp-1 {
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  &.line-clamp-2 {
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
  &.line-clamp-3 {
    -webkit-line-clamp: 3;
    word-break: break-word;
  }
}
.item-list {
  display: flex;
  flex-wrap: wrap;
  &.list {
    padding: 0 0.65em;
    margin: 1em 0;
    > * {
      list-style: none;
      display: block;
      flex: 1 1 auto;
      &:not(:last-child) {
        padding-bottom: 0.9em;
        margin-bottom: 0.9em;
        border-bottom: 1px solid #dddddd;
      }
    }
  }
  &.card {
    padding: 0 0.65em;
    margin: 1em 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 0.8em;
    > * {
      list-style: none;
      display: block;
      min-width: 56vw;
    }
  }
  &.grid {
    padding: 0 6px;
    display: flex;
    flex-wrap: wrap;
    > * {
      width: calc(50% - 12px);
      list-style: none;
      margin: 0px 6px 12px 6px;
    }
  }
}
